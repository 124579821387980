import React from 'react';

import logoStyles from './logo.module.scss';

const Logo: React.FC<{ light?: boolean }> = ({ light }) => (
  <figure className={`${logoStyles.logo} ${light && logoStyles.logoLight}`}>
    <h1>
      <span>W</span>olf-Georg <span>W</span>inkler
    </h1>
    <span className={logoStyles.hline}>Rechtsanwalt</span>
  </figure>
);

export default Logo;
