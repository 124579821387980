import React from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { Twitter, Facebook, Instagram } from 'react-feather';
import Logo from './logo';

import footerStyles from './footer.module.scss';
import { useStaticQuery, graphql, Link } from 'gatsby';
import {
  Query,
  StrapiAbout,
  StrapiAreaOfLaw,
  StrapiSocialLinks,
  StrapiImprint,
} from '../graphql-types';

const Footer: React.FC = () => {
  const {
    strapiAbout: about,
    allStrapiAreaOfLaw: { nodes: areasOfLaw },
    strapiSocialLinks: socialLinks,
    strapiImprint: imprint,
  } = useStaticQuery<
    Pick<
      Query,
      | 'strapiAbout'
      | 'allStrapiAreaOfLaw'
      | 'strapiSocialLinks'
      | 'strapiImprint'
    >
  >(graphql`
    {
      strapiAbout {
        slug
        title
      }

      allStrapiAreaOfLaw {
        nodes {
          slug
          title
        }
      }

      strapiSocialLinks {
        facebook
        twitter
        instagram
      }

      strapiImprint {
        slug
        title
      }
    }
  `);

  return (
    <footer className={`mt-5 pt-5 pb-3 ${footerStyles.footer}`}>
      <Container className="text-light">
        <FooterTop about={about} areasOfLaw={areasOfLaw} imprint={imprint} />
        <FooterBottom socialLinks={socialLinks} />
      </Container>
    </footer>
  );
};

export default Footer;

const FooterTop: React.FC<{
  about: StrapiAbout;
  areasOfLaw: StrapiAreaOfLaw[];
  imprint: StrapiImprint;
}> = ({ about, areasOfLaw, imprint }) => {
  return (
    <Row>
      <Col
        xs="12"
        md="6"
        lg="8"
        className="text-center text-md-left mb-5 mb-md-0"
      >
        <Logo light />
      </Col>
      <Col xs="6" md="3" lg="2">
        <Nav vertical>
          <NavItem>
            <Link className="nav-link text-light" to={`/${about.slug}`}>
              {about.title}
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link text-light" to={`/${imprint.slug}`}>
              {imprint.title}
            </Link>
          </NavItem>
        </Nav>
      </Col>
      <Col xs="6" md="3" lg="2">
        <Nav vertical>
          {areasOfLaw.map(({ title, slug }) => (
            <NavItem key={slug}>
              <Link className="nav-link text-light" to={`/${slug}`}>
                {title}
              </Link>
            </NavItem>
          ))}
        </Nav>
      </Col>
    </Row>
  );
};

const FooterBottom: React.FC<{ socialLinks: StrapiSocialLinks }> = ({
  socialLinks: { twitter, facebook, instagram },
}) => (
  <aside
    className={`pt-4 mt-4 d-flex justify-content-between align-items-center ${footerStyles.footerBottom}`}
  >
    <div>Copyright &copy;2020 | Alle Rechte vorbehalten.</div>
    <div>
      <Nav>
        {twitter && (
          <NavItem>
            <NavLink className="text-light" href={twitter}>
              <Twitter />
            </NavLink>
          </NavItem>
        )}
        {facebook && (
          <NavItem>
            <NavLink className="text-light" href={facebook}>
              <Facebook />
            </NavLink>
          </NavItem>
        )}
        {instagram && (
          <NavItem>
            <NavLink className="text-light" href={instagram}>
              <Instagram />
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </div>
  </aside>
);
