import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  Query,
  StrapiAbout,
  StrapiAreaOfLaw,
  StrapiImprint,
} from '../graphql-types';
import Logo from './logo';

const AboutLink: React.FC<StrapiAbout> = ({ title, slug }) => (
  <NavItem key={slug}>
    <Link className="nav-link" to={`/${slug}`} activeClassName="active">
      {title}
    </Link>
  </NavItem>
);

const AreasOfLawLinks: React.FC<{
  areasOfLaw: StrapiAreaOfLaw[];
  title: string;
}> = ({ areasOfLaw, title: dropdownTitle }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!isDropdownOpen);

  return (
    <Dropdown nav isOpen={isDropdownOpen} toggle={toggle} inNavbar>
      <DropdownToggle nav caret>
        {dropdownTitle}
      </DropdownToggle>
      <DropdownMenu right>
        {areasOfLaw.map(({ title, slug }) => (
          <DropdownItem
            tag={({ className, role, tabIndex, children }) => {
              const props = { className, role, tabIndex };

              return (
                <Link to={`/${slug}`} activeClassName="active" {...props}>
                  {children}
                </Link>
              );
            }}
            key={slug}
          >
            {title}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const ImprintLink: React.FC<StrapiImprint> = ({ slug, title }) => (
  <NavItem>
    <Link className="nav-link" to={`/${slug}`} activeClassName="active">
      {title}
    </Link>
  </NavItem>
);

const Navigation: React.FC = () => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!isOpen);

  const {
    strapiAbout,
    allStrapiAreaOfLaw: { nodes: areasOfLaw },
    strapiAreasOfLaw,
    strapiImprint,
  } = useStaticQuery<
    Pick<
      Query,
      | 'strapiAbout'
      | 'allStrapiAreaOfLaw'
      | 'strapiAreasOfLaw'
      | 'strapiImprint'
    >
  >(graphql`
    {
      strapiAbout {
        slug
        title
      }

      strapiAreasOfLaw {
        title
      }

      allStrapiAreaOfLaw {
        nodes {
          title
          slug
        }
      }

      strapiImprint {
        slug
        title
      }
    }
  `);

  return (
    <Navbar color="light" light expand="md" className="bg-white shadow-sm">
      <Link className="navbar-brand" to="/" style={{ textDecoration: 'none' }}>
        <Logo />
      </Link>
      <NavbarToggler onClick={toggle} style={{ border: 'none' }} />
      <Collapse navbar isOpen={isOpen}>
        <Nav navbar className="ml-auto">
          <AboutLink {...strapiAbout} />
          <AreasOfLawLinks
            areasOfLaw={areasOfLaw}
            title={strapiAreasOfLaw.title}
          />
          <ImprintLink {...strapiImprint} />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Navigation;
